define('bourbon/components/bourbon-svg--upgrade-badge', ['exports', 'bourbon/templates/components/bourbon-svg--upgrade-badge'], function (exports, _bourbonSvgUpgradeBadge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bourbonSvgUpgradeBadge.default,
    tagName: '',
    size: 'medium',
    color: 'black',
    iconSize: Ember.computed('size', function () {
      if (this.get('size') === 'small') {
        return '18px';
      } else if (this.get('size') === 'medium') {
        return '24px';
      } else if (this.get('size') === 'large') {
        return '36px';
      }
    }),

    iconColor: Ember.computed('color', function () {
      return 'btw-text-' + this.get('color') + ' btw-fill-current';
    })
  });
});