define("bourbon/templates/components/bourbon-confirm-icon", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "bourbon/templates/components/bourbon-confirm-icon.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 100 100");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill", "#000");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M50 74.267c13.402 0 24.267-10.865 24.267-24.267S63.402 25.733 50 25.733 25.733 36.598 25.733 50 36.598 74.267 50 74.267zM50 78c-15.464 0-28-12.536-28-28s12.536-28 28-28 28 12.536 28 28-12.536 28-28 28z");
        dom.setAttribute(el3, "fill-rule", "nonzero");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M46.72 54.092l11.879-11.879 2.64 2.64-14.52 14.52-7.919-7.92 2.64-2.64z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'width');
        morphs[2] = dom.createAttrMorph(element0, 'height');
        return morphs;
      },
      statements: [["attribute", "class", ["get", "iconColor", ["loc", [null, [1, 13], [1, 22]]]]], ["attribute", "width", ["get", "iconSize", ["loc", [null, [1, 33], [1, 41]]]]], ["attribute", "height", ["get", "iconSize", ["loc", [null, [1, 53], [1, 61]]]]]],
      locals: [],
      templates: []
    };
  }());
});