define('bourbon/components/bourbon-search-select-field', ['exports', 'bourbon/templates/components/bourbon-search-select-field', 'bourbon/mixins/select', 'bourbon/mixins/click-handler'], function (exports, _bourbonSearchSelectField, _select, _clickHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_select.default, _clickHandler.default, {
    layout: _bourbonSearchSelectField.default,
    classNames: ['BourbonSearchSelectField'],
    classNameBindings: ['showDropdown:btw-z-20', 'showDropdown:BourbonSearchSelectField--active', 'disabled:BourbonSearchSelectField--disabled'],
    isOpen: false,
    activeOption: null,

    init: function init() {
      this._super.apply(this, arguments);
      // TODO figure out if we still need searchList
      this.set('searchList', this.get('content'));
    },


    value: null,
    prompt: null,
    inputValue: '',
    showDropdown: false,
    searchList: null,
    optionValuePath: null,
    optionLabelPath: null,
    optionEnabledPath: null,
    autofocus: null,
    readonly: null,
    disabled: false,

    clickHandler: function clickHandler(e) {
      if (e.target !== document.activeElement) {
        this.resetPrompt();
        this.set('activeOption', null);
        this.set('showDropdown', false);
        this.set('autofocus', false);
        this.set('readonly', true);
      }
    },
    input: function input(e) {
      var el = $(e.currentTarget);
      var textInput = el.find('.BourbonTextField-input').val();

      this.set('inputValue', textInput);
    },


    resetPrompt: Ember.observer('label', 'value', function () {
      if (this.get('value') === null && this.get('prompt')) {
        this.set('inputValue', this.get('prompt'));
      } else if (this.get('label')) {
        this.set('inputValue', this.get('label'));
      }
    }),

    noResults: Ember.computed('searchList.[]', function () {
      if (this.get('groupedContent')) {
        return this.get('searchList')[0]['items'][0]['label'] === 'No results found.';
      } else if (this.get('searchList')[0]) {
        return this.get('searchList')[0] === 'No results found.' || this.get('searchList')[0]['label'] === 'No results found.';
      }
    }),

    keyDown: function keyDown(e) {
      this.moveUpDown(e);
      // e.keyCode 13 is for 'Enter'
      if (e.keyCode === 13) {
        e.preventDefault();
        // if valid option available select first option upon enter
        if (!this.get('noResults') && this.get('searchList').length > 0) {
          // only select the first option if we have not keyed down
          // to a different selection
          if (this.get('activeOption') === null) {
            this.set('activeOption', 0);
          }
        }
        this.send('updateSearchSelection', this.get('activeOption'));
        this.set('activeOption', null);
        this.set('showDropdown', false);
        this.set('autofocus', false);
        this.set('readonly', true);
        document.activeElement.blur();
      } else if (e.keyCode === 8) {
        // e.keyCode 8 is for 'Delete'
        // since inputValue is being set manually we need to
        // manually change the inputValue when the user is
        // using the delete button to change their input
        e.preventDefault();
        this.set('inputValue', this.get('inputValue').slice(0, -1));
      }
    },
    optionValue: function optionValue(option) {
      if (typeof option === 'string') {
        return option.toLowerCase();
      } else if (typeof option === 'number') {
        return option.toString();
      } else if (typeof option.get === 'function') {
        return option.get('label').toLowerCase();
      } else {
        return option.label.toLowerCase();
      }
    },
    getSearchList: function getSearchList(searchString) {
      var _this = this;

      if (this.get('groupedContent')) {
        var searchGroupList = [];

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.get('content')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var group = _step.value;

            var newGroupItems = group.items.filter(function (option) {
              return _this.optionValue(option).match(searchString);
            });

            if (newGroupItems.length) {
              searchGroupList.push({
                groupHeader: group.groupHeader,
                items: newGroupItems
              });
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return searchGroupList;
      } else {
        return this.get('content').filter(function (option) {
          return _this.optionValue(option).match(searchString);
        });
      }
    },


    searchResults: Ember.observer('inputValue', 'content', function () {
      if (this.get('inputValue') === '') {
        this.set('searchList', this.get('content'));
      } else {
        var selectedValue = this.get('inputValue') ? this.get('inputValue') : this.get('value');

        var searchString = this.optionValue(selectedValue);

        var searchList = this.getSearchList(searchString);

        if (searchList.length === 0) {
          if (this.get('groupedContent')) {
            this.set('searchList', Ember.A([{ groupHeader: null, items: [{ label: 'No results found.' }] }]));
          } else if (this.get('optionLabelPath')) {
            this.set('searchList', Ember.A([{ label: 'No results found.' }]));
          } else {
            this.set('searchList', Ember.A(['No results found.']));
          }
        } else {
          this.set('searchList', Ember.A(searchList));
        }

        return searchList;
      }
    }),

    selection: Ember.computed('value', {
      get: function get(key) {
        this.getSelection();
      },
      set: function set(key, value) {
        if (Ember.isPresent(value)) {
          if (this.get('groupedContent') && value.groupHeader) {
            value = value.items[0];
          }
          this.setValue(value);
          this.set('inputValue', this.get('label'));
        } else {
          this.set('value', null);
          this.set('inputValue', this.get('prompt'));
        }
        this.set('activeOption', null);
        return value;
      }
    }),

    didInsertElement: function didInsertElement() {
      this.send('updateSearchSelection');
    },


    actions: {
      updateSearchSelection: function updateSearchSelection(selectedIndex) {
        var outerIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        // for key up and down selection
        if (this.get('groupedContent')) {
          if (outerIndex) {
            this.set('selection', this.get('searchList')[outerIndex].items[selectedIndex]);
          } else {
            var groupList = [];
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = this.get('searchList')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var option = _step2.value;

                groupList.push.apply(groupList, _toConsumableArray(option.items));
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            this.set('selection', groupList[selectedIndex]);
          }
        } else {
          if (selectedIndex === undefined) {
            if (Ember.isPresent(this.findValueObject(this.get('value')))) {
              this.set('selection', this.findValueObject(this.get('value')));
              return;
            } else {
              selectedIndex = 0;
              if (this.get('prompt')) {
                selectedIndex -= 1;
              }
            }
          }
          if (this.get('searchList')) {
            this.set('selection', this.get('searchList').objectAt(selectedIndex));
          }
        }

        this.set('showDropdown', false);
      },
      mouseDown: function mouseDown() {
        this.set('showDropdown', !this.get('showDropdown'));

        if (this.get('showDropdown')) {
          this.set('inputValue', '');
          this.set('autofocus', true);
          this.set('readonly', null);
        } else {
          this.set('autofocus', false);
          this.set('readonly', true);
          this.resetPrompt();
        }
      }
    }
  });
});