define('bourbon/components/bourbon-modal-content', ['exports', 'bourbon/templates/components/bourbon-modal-content'], function (exports, _bourbonModalContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['BourbonModal-content'],
    classNameBindings: ['noPadding:btw-p-0', 'noPadding:btw-m-0'],
    layout: _bourbonModalContent.default,

    actions: {
      closeBourbonModal: function closeBourbonModal() {
        this.get('closeBourbonModal')();
      }
    }
  });
});