define('bourbon/components/bourbon-text-field', ['exports', 'bourbon/templates/components/bourbon-text-field'], function (exports, _bourbonTextField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['BourbonTextField'],
    classNameBindings: ['isFocused:BourbonTextField--active', 'isNotEmpty:BourbonTextField--not-empty'],

    layout: _bourbonTextField.default,

    actionOnFocusIn: '',
    actionOnFocusOut: '',
    actionOnEnter: '',
    onFocusOutOrEnter: null,
    autofocus: false,
    readonly: null,
    value: null,
    isFocused: false,
    noLabel: false,

    fieldType: Ember.computed('type', function () {
      return this.get('type') ? this.get('type') : 'text';
    }),

    // attribute binding doesn't work for readonly = false
    // https://stackoverflow.com/questions/16109358/what-is-the-correct-readonly-attribute-syntax-for-input-text-elements
    boundReadOnly: Ember.computed('readonly', function () {
      return this.get('readonly') || null;
    }),

    focusedElementObserver: Ember.observer('autofocus', function () {
      this.set('isFocused', this.get('autofocus'));
    }),

    isNotEmpty: Ember.computed('value', function () {
      return this.get('value') && !this.get('noLabel');
    }),

    didInsertElement: function didInsertElement() {
      if (this.get('autofocus')) {
        this.$().focus();
        this.set('isFocused', true);
      }
    },
    focusIn: function focusIn() {
      this.set('isFocused', true);
      if (this.get('actionOnFocusIn')) {
        this.get('actionOnFocusIn')();
      }
    },
    input: function input(e) {
      var el = $(e.currentTarget);
      var textInput = el.find('.BourbonTextField-input').val();
      this.set('value', textInput);
    },
    focusOut: function focusOut() {
      this.set('isFocused', false);
      document.activeElement.blur();

      if (this.get('onFocusOutOrEnter')) {
        this.get('onFocusOutOrEnter')(this.get('value'));
      }

      if (this.get('actionOnFocusOut')) {
        this.get('actionOnFocusOut')(this.get('value'));
      }
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === 13) {
        if (this.get('actionOnEnter')) {
          this.get('actionOnEnter')(this.get('value'));
        }

        if (this.get('onFocusOutOrEnter')) {
          this.get('onFocusOutOrEnter')(this.get('value'));
        }
      }
    }
  });
});