define('bourbon/mixins/click-handler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('clickOutsideElement', this.get('clickHandler').bind(this));
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      document.addEventListener('click', this.get('clickOutsideElement'), false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('click', this.get('clickOutsideElement'), false);
    }
  });
});