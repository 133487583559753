// Refer to source: app/assets/javascripts/js_ext.coffee

/*
 * Returns the integer part of a number as a comma-delimited string.
 * If precision is given, converts to fixed precision first.
 * Ref: https://stackoverflow.com/a/2901298/1677912
 */
Number.prototype.commafy = function(precision) {
  var numStr, parts;
  numStr = precision ? this.toFixed(precision) : this.toString();
  parts = numStr.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  numStr = parts.join(".");
  return numStr;
};

Number.prototype.format = function(precision, currency) {
  var numRnd, numStr;
  if (precision == null) {
    precision = 2;
  }
  if (currency == null) {
    currency = false;
  }
  numRnd = Math.round(this * Math.pow(10, precision)) / Math.pow(10, precision);
  if (currency) {
    numStr = numRnd.commafy(precision);
    numStr = numStr.replace(/(\d)/, '$$$1');
  } else {
    numStr = numRnd.commafy();
  }
  return numStr;
};

Number.prototype.roundTo = function(place) {
  var numZeroes;
  if (place == null) {
    place = 0;
  }
  if (place > 0) {
    numZeroes = Math.pow(10, place);
    return Math.round(this * numZeroes) / numZeroes;
  } else {
    return Math.round(this);
  }
};

String.prototype.splice = function(str, idx, endIdx) {
  if (endIdx == null) {
    endIdx = idx;
  }
  return this.slice(0, idx) + str + this.slice(Math.max(idx, endIdx));
};

String.prototype.escapeRegexp = function() {
  return this.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
};

if (typeof Object.assign != 'function') {
  (function () {
    Object.assign = function (target) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var output = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var source = arguments[index];
        if (source !== undefined && source !== null) {
          for (var nextKey in source) {
            if (source.hasOwnProperty(nextKey)) {
              output[nextKey] = source[nextKey];
            }
          }
        }
      }
      return output;
    };
  })();
}
