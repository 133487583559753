define('bourbon/components/bourbon-demo-prompt', ['exports', 'bourbon/templates/components/bourbon-demo-prompt'], function (exports, _bourbonDemoPrompt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['BourbonDemoPrompt-container'],
    layout: _bourbonDemoPrompt.default,

    actions: {
      buttonAction: function buttonAction() {
        this.get('buttonAction')();
      }
    }
  });
});