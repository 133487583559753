define("bourbon/templates/components/bourbon-sf-opportunity-icon", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "bourbon/templates/components/bourbon-sf-opportunity-icon.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 100 100");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M71.1 70H28.9c-1 0-1.9.9-1.9 1.9v.1c0 3.3 2.7 6 6 6h34c3.3 0 6-2.7 6-6v-.1c0-1-.9-1.9-1.9-1.9zM76 29c-3.3 0-6 2.7-6 6 0 1.8.8 3.5 2.1 4.6-1.7 3.9-5.6 6.6-10.2 6.4-5.3-.3-9.6-4.6-9.9-9.9 0-.9 0-1.7.2-2.5 2.2-.9 3.8-3 3.8-5.6 0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2.5 1.6 4.7 3.8 5.6.2.8.2 1.6.2 2.5-.3 5.3-4.6 9.6-9.9 9.9-4.6.3-8.6-2.5-10.2-6.4 1.3-1.1 2.1-2.8 2.1-4.6 0-3.3-2.7-6-6-6s-6 2.7-6 6 2.7 6 6 6l2.8 21.4c.1.9.9 1.6 1.9 1.6h42.6c.9 0 1.7-.7 1.9-1.6L76 41c3.3 0 6-2.7 6-6s-2.7-6-6-6z");
        dom.setAttribute(el2, "stroke", "none");
        dom.setAttribute(el2, "fill", "#000");
        dom.setAttribute(el2, "fill-rule", "nonzero");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'width');
        morphs[2] = dom.createAttrMorph(element0, 'height');
        return morphs;
      },
      statements: [["attribute", "class", ["get", "iconColor", ["loc", [null, [1, 13], [1, 22]]]]], ["attribute", "width", ["get", "iconSize", ["loc", [null, [1, 33], [1, 41]]]]], ["attribute", "height", ["get", "iconSize", ["loc", [null, [1, 53], [1, 61]]]]]],
      locals: [],
      templates: []
    };
  }());
});