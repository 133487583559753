define("bourbon/templates/components/bourbon-sf-contact-icon", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "bourbon/templates/components/bourbon-sf-contact-icon.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 100 100");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M75.6 30H24.4c-3.52 0-6.4 2.898-6.4 6.439v31.122C18 71.102 20.88 74 24.4 74h51.2c3.52 0 6.4-2.898 6.4-6.439V36.439C82 32.898 79.12 30 75.6 30zM48.507 66.488H30.16c-2.027 0-3.627-2.254-3.627-4.4.107-3.22 3.414-5.151 6.934-6.761 2.453-1.073 2.773-2.04 2.773-3.112 0-1.074-.64-2.04-1.493-2.79-1.387-1.288-2.24-3.22-2.24-5.366 0-4.079 2.453-7.513 6.72-7.513 4.266 0 6.72 3.434 6.72 7.513 0 2.146-.747 4.078-2.24 5.365-.854.752-1.494 1.717-1.494 2.79 0 1.074.32 2.04 2.774 3.006 3.52 1.502 6.826 3.648 6.933 6.868.213 2.146-1.387 4.4-3.413 4.4zm24.96-7.512c0 1.18-.96 2.146-2.134 2.146h-9.6a2.146 2.146 0 0 1-2.133-2.146v-3.22c0-1.18.96-2.146 2.133-2.146h9.6c1.174 0 2.134.966 2.134 2.146v3.22zm0-11.805c0 1.18-.96 2.146-2.134 2.146h-16a2.146 2.146 0 0 1-2.133-2.146v-3.22c0-1.18.96-2.146 2.133-2.146h16c1.174 0 2.134.966 2.134 2.146v3.22z");
        dom.setAttribute(el3, "fill", "#000000");
        dom.setAttribute(el3, "fill-rule", "nonzero");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'width');
        morphs[2] = dom.createAttrMorph(element0, 'height');
        return morphs;
      },
      statements: [["attribute", "class", ["get", "iconColor", ["loc", [null, [1, 13], [1, 22]]]]], ["attribute", "width", ["get", "iconSize", ["loc", [null, [1, 33], [1, 41]]]]], ["attribute", "height", ["get", "iconSize", ["loc", [null, [1, 53], [1, 61]]]]]],
      locals: [],
      templates: []
    };
  }());
});