define("bourbon/templates/components/bourbon-text-field", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "bourbon/templates/components/bourbon-text-field.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "BourbonTextField-label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["content", "placeholder", ["loc", [null, [1, 38], [1, 53]]]], ["inline", "input", [], ["class", "BourbonTextField-input", "type", ["subexpr", "@mut", [["get", "fieldType", ["loc", [null, [2, 44], [2, 53]]]]], [], []], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [2, 66], [2, 77]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [2, 84], [2, 89]]]]], [], []], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [2, 99], [2, 107]]]]], [], []], "autocomplete", ["subexpr", "@mut", [["get", "autocomplete", ["loc", [null, [2, 121], [2, 133]]]]], [], []], "autofocus", ["subexpr", "@mut", [["get", "autofocus", ["loc", [null, [2, 144], [2, 153]]]]], [], []], "readonly", ["subexpr", "@mut", [["get", "boundReadOnly", ["loc", [null, [2, 163], [2, 176]]]]], [], []], "maxlength", ["subexpr", "@mut", [["get", "maxlength", ["loc", [null, [2, 187], [2, 196]]]]], [], []]], ["loc", [null, [2, 0], [2, 198]]]]],
      locals: [],
      templates: []
    };
  }());
});