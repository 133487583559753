define('bourbon/components/bourbon-alert-badge', ['exports', 'bourbon/templates/components/bourbon-alert-badge'], function (exports, _bourbonAlertBadge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['BourbonAlertBadge-container'],
    layout: _bourbonAlertBadge.default
  });
});