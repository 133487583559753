define("bourbon/templates/components/bourbon-sf-lead-icon", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "bourbon/templates/components/bourbon-sf-lead-icon.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 100 100");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M50 38.525c-4.49 0-8.129-3.7-8.129-8.263S45.511 22 50 22c4.49 0 8.129 3.7 8.129 8.262 0 4.563-3.64 8.263-8.129 8.263zm25.29 5.508c1.807 0 2.53 2.295.994 3.305l-13.187 8.63c-.632.458-.903 1.284-.723 2.019l4.968 16.8c.452 1.836-1.897 3.121-3.161 1.744l-12.826-13.77a1.79 1.79 0 0 0-2.71 0L35.82 76.53c-1.354 1.377-3.703.092-3.16-1.744l4.967-16.8c.27-.735-.09-1.56-.723-2.02l-13.187-8.63c-1.535-1.01-.813-3.304.994-3.304h50.58z");
        dom.setAttribute(el2, "fill", "#000");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'width');
        morphs[2] = dom.createAttrMorph(element0, 'height');
        return morphs;
      },
      statements: [["attribute", "class", ["get", "iconColor", ["loc", [null, [1, 13], [1, 22]]]]], ["attribute", "width", ["get", "iconSize", ["loc", [null, [1, 33], [1, 41]]]]], ["attribute", "height", ["get", "iconSize", ["loc", [null, [1, 53], [1, 61]]]]]],
      locals: [],
      templates: []
    };
  }());
});