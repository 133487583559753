var mutableArrayExtras, reopenForEach;

reopenForEach = function(array, callback) {
  var i, item, len, newProperties;
  newProperties = {};
  for (i = 0, len = array.length; i < len; i++) {
    item = array[i];
    callback(item, newProperties);
  }
  this.reopen(newProperties);
  return _keys(newProperties);
};

Ember.Object.reopenClass({
  reopenForEach: reopenForEach
});

Ember.Mixin.prototype.reopenForEach = reopenForEach;

Ember.Object.reopen({
  setnx: function(keyName, value, strict) {
    if ((strict ? Ember.isNone : Ember.isEmpty)(this.get(keyName))) {
      this.set(keyName, value);
    }
    return this;
  }
});

Ember.String.singularize = function(str) {
  if (str.lastIndexOf('s') === str.length - 1) {
    return str.substring(0, str.length - 1);
  } else {
    return str;
  }
};

String.prototype.singularize = function() {
  return Ember.String.singularize(this);
};

Ember.String.pluralize = function(str) {
  if (str.lastIndexOf('s') === str.length - 1) {
    return str + 'es';
  } else {
    return str + 's';
  }
};

String.prototype.pluralize = function() {
  return Ember.String.pluralize(this);
};

Ember.String.titleize = function(str) {
  return str.replace(/(?:^|\s)\S/g, function(c) {
    return c.toUpperCase();
  });
};

String.prototype.titleize = function() {
  return Ember.String.titleize(this);
};

if (!String.prototype.trim) {
  Ember.String.trim = function(str) {
    return str.replace(/^\s+|\s+$/g, '');
  };
  String.prototype.trim = function() {
    return Ember.String.trim(this);
  };
}

Ember.String.decapitalize = function(str) {
  return str.charAt(0).toLowerCase() + str.substr(1);
};

String.prototype.decapitalize = function() {
  return Ember.String.decapitalize(this);
};

mutableArrayExtras = {
  shuffle: function() {
    var index, rand, shuffled;
    rand = null;
    index = 0;
    shuffled = [];
    this.forEach(function(obj) {
      rand = Math.floor(Math.random() * (index++ + 1));
      shuffled[index - 1] = shuffled[rand];
      return shuffled[rand] = obj;
    });
    this.replace(0, this.get('length'), shuffled);
    return this;
  },
  flatten: function() {
    var flattened;
    flattened = [];
    return flattened.concat.apply(flattened, this);
  }
};

Ember.MutableArray.reopen(mutableArrayExtras);

Ember.Mixin.create(mutableArrayExtras).apply(Array.prototype);

Ember.computed.translate = function(dependentKey, map) {
  var key, reverseMap, value;
  reverseMap = {};
  for (key in map) {
    value = map[key];
    reverseMap[value] = key;
  }
  return Ember.computed(dependentKey,
    {
      get: function(key)  {
        return map[this.get(dependentKey)];
      },
      set: function(key, value) {
        this.set(dependentKey, reverseMap[value]);
        return value;
      }
    }
  );
};

Array.prototype.replaceAt = function(index, item) {
  return this.replace(index, 1, [item]);
};

Ember.Router.reopen({
  observedHandlerInfos: [],
  didTransition: function(infos) {
    var observed;
    this._super.apply(this, arguments);
    observed = this.get('observedHandlerInfos');
    observed.forEach((function(_this) {
      return function(info) {
        return Ember.removeObserver(info.handler, 'title', _this, _this.updateTitle);
      };
    })(this));
    observed.clear();
    infos.forEach((function(_this) {
      return function(info) {
        return Ember.addObserver(info.handler, 'title', _this, _this.updateTitle);
      };
    })(this));
    observed.pushObjects(infos);
    return this.updateTitle();
  },
  updateTitle: function() {
    var currentHandlerInfos, parts, title;
    currentHandlerInfos = this.get('router.currentHandlerInfos');
    parts = [];
    currentHandlerInfos.forEach(function(info) {
      var part;
      if ((part = info.handler.get('title'))) {
        return parts.pushObject(part);
      }
    });
    title = parts.reverse().join(' - ');
    if (title) {
      return document.title = title;
    }
  }
});

Ember.computed.rejectBy = function(dependentKey, propertyKey, value) {
  var callback;
  if (arguments.length === 2) {
    callback = function(item) {
      return !Ember.get(item, propertyKey);
    };
  } else {
    callback = function(item) {
      return Ember.get(item, propertyKey) !== value;
    };
  }
  return Ember.computed.filter(dependentKey + '.@each.' + propertyKey, callback);
};

Ember.LinkComponent.reopen({
  attributeBindings: ['draggable'],
  activeClass: 'is-active'
});

Ember.computed.indexBy = function(dependentKey, propertyKey) {
  return Ember.reduceComputed(dependentKey + ".@each." + propertyKey, {
    initialValue: function() {
      return Ember.Map.create();
    },
    addedItem: function(accumulatedValue, item, changeMeta, instanceMeta) {
      var key, values;
      key = item.get(propertyKey);
      if ((values = accumulatedValue.get(key))) {
        values.pushObject(item);
      } else {
        accumulatedValue.set(key, [item]);
      }
      return accumulatedValue;
    },
    removedItem: function(accumulatedValue, item, changeMeta, instanceMeta) {
      var key, values;
      if (changeMeta.previousValues) {
        key = changeMeta.previousValues[propertyKey];
      } else {
        key = item.get(propertyKey);
      }
      if ((values = accumulatedValue.get(key)).length === 1) {
        accumulatedValue["delete"](key);
      } else {
        accumulatedValue.get(key).removeObject(item);
      }
      return accumulatedValue;
    }
  });
};

Ember.computed.notEqual = function(dependentKey, value) {
  return Ember.computed(dependentKey, function() {
    return this.get(dependentKey) !== value;
  });
};

Ember.computed.stored = function(idKey) {
  var storageKey;
  storageKey = function(key) {
    var keyParts;
    keyParts = [];
    keyParts.push("" + this.constructor);
    if (idKey) {
      keyParts.push(this.get(idKey));
    }
    keyParts.push(key);
    return keyParts.join(':');
  };
  return Ember.computed({
    get: function(key) {
      try {
        return store.get(storageKey.call(this, key));
      } catch (error) {}
    },
    set: function(key, value) {
      try {
        if (Ember.isBlank(value)) {
          store.remove(storageKey.call(this, key));
          return void 0;
        } else {
          store.set(storageKey.call(this, key), value);
          return value;
        }
      } catch (error) {}
    }
  });
};


// Additional keyUp event methods for all Ember.TextFields
_extend(Ember.TextSupport.KEY_EVENTS, {
  8:  'backspace',
  9:  'tab',
  37: 'arrowLeft',
  38: 'arrowUp',
  39: 'arrowRight',
  40: 'arrowDown'
});

Ember.TextSupport.reopen({
  backspace:  Ember.K,
  tab:        Ember.K,
  arrowLeft:  Ember.K,
  arrowUp:    Ember.K,
  arrowRight: Ember.K,
  arrowDown:  Ember.K
});

// Additional attributes to pass down to the HTML tag
Ember.TextSupport.reopen({attributeBindings: ['readonly']});
