define('bourbon/components/bourbon-accordion-item', ['exports', 'bourbon/templates/components/bourbon-accordion-item'], function (exports, _bourbonAccordionItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bourbonAccordionItem.default,
    tagName: 'li',
    classNames: ['BourbonAccordionItem'],
    isOpen: Ember.computed('listItem.open', function () {
      if (this.get('listItem.open') === true) {
        return true;
      } else {
        return false;
      }
    }),

    isToggleable: Ember.computed('listItem.toggleable', function () {
      if (this.get('listItem.toggleable') === false) {
        return false;
      } else {
        return true;
      }
    }),

    actions: {
      openItem: function openItem() {
        if (this.get('isToggleable')) {
          this.toggleProperty('isOpen');
        }
      }
    }
  });
});