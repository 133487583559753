define('bourbon/components/bourbon-feature-overview', ['exports', 'bourbon/templates/components/bourbon-feature-overview'], function (exports, _bourbonFeatureOverview) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['BourbonFeatureOverview-container'],
    buttonOneClass: Ember.computed('buttonOneType', function () {
      if (this.get('buttonOneType')) {
        return 'BourbonButton--' + this.get('buttonOneType') + ' BourbonButton--rounded';
      } else {
        return 'BourbonButton--primary BourbonButton--rounded';
      }
    }),

    buttonTwoClass: Ember.computed('buttonTwoType', function () {
      if (this.get('buttonTwoType')) {
        return 'BourbonButton--' + this.get('buttonTwoType') + ' BourbonButton--rounded';
      } else {
        return 'BourbonButton--primary BourbonButton--rounded';
      }
    }),

    layout: _bourbonFeatureOverview.default,

    actions: {
      buttonOneAction: function buttonOneAction() {
        if (typeof this.get('buttonOneAction') === 'function') {
          this.get('buttonOneAction')();
        } else {
          /* eslint no-console: 0 */
          console.warn('warning: no button action passed');
        }
      },
      buttonTwoAction: function buttonTwoAction() {
        if (typeof this.get('buttonTwoAction') === 'function') {
          this.get('buttonTwoAction')();
        } else {
          /* eslint no-console: 0 */
          console.warn('warning: no button action passed');
        }
      }
    }
  });
});