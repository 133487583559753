define('bourbon/components/bourbon-table', ['exports', 'bourbon/templates/components/bourbon-table'], function (exports, _bourbonTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['BourbonTable'],
    tagName: 'table',
    layout: _bourbonTable.default
  });
});