define('bourbon/mixins/select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    setValue: function setValue(value) {
      var path = this.get('_valuePath');
      var checkValue = this.getCheckValue(value);

      if (path && Ember.isPresent(checkValue)) {
        if (typeof checkValue.get === 'function') {
          checkValue = checkValue.get(path);
        } else {
          checkValue = checkValue[path];
        }
      }

      this.set('value', checkValue);
    },
    getCheckValue: function getCheckValue(value) {
      // TODO check if still needed
      if (value.groupHeader) {
        return this.get('value');
      } else {
        if (typeof value === 'number') {
          return value;
        } else {
          return Ember.isPresent(value) ? value : this.get('value');
        }
      }
    },


    label: Ember.computed('selection', 'content', function () {
      var checkValue = this.get('value');

      if (typeof checkValue === 'string' || typeof checkValue === 'number' || typeof checkValue === 'boolean') {
        checkValue = this.findValueObject(checkValue);
      }

      if (checkValue === null || checkValue === undefined) {
        return this.get('prompt');
      }

      var path = this.get('_labelPath');
      if (path && Ember.isPresent(checkValue)) {
        if (typeof checkValue.get === 'function') {
          return checkValue.get(path); // if Ember.Object
        } else {
          return checkValue[path]; // if JS object
        }
      } else {
        return checkValue;
      }
    }),

    _valuePath: Ember.computed('optionValuePath', function () {
      if (this.get('optionValuePath') !== null) {
        return this.get('optionValuePath').replace(/^content\.?/, '');
      }
    }),

    _labelPath: Ember.computed('optionLabelPath', function () {
      if (this.get('optionLabelPath') !== null) {
        return this.get('optionLabelPath').replace(/^content\.?/, '');
      }
    }),

    findValueObject: function findValueObject(value) {
      var path = this.get('_valuePath');

      if (path) {
        if (this.get('groupedContent')) {
          var groupList = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = this.get('searchList')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var option = _step.value;

              groupList.push.apply(groupList, _toConsumableArray(option.items));
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          if (groupList) {
            return groupList.find(function (v) {
              return v[path] === value;
            });
          }
        } else {
          if (this.get('content')) {
            return this.get('content').find(function (v) {
              return v[path] === value;
            });
          }
        }
      } else {
        if (this.get('content')) {
          return this.get('content').find(function (v) {
            return v === value;
          });
        }
      }
    },
    moveUpDown: function moveUpDown(e) {
      var el = $(e.currentTarget);

      var list = el.find('.BourbonSelectField-menu');
      var allOptions = el.find('.BourbonSelectField-menu .BourbonSelectField-option');
      var numOptions = allOptions.length;

      if (e.keyCode === 40) {
        // e.keyCode 40 is for 'down arrow'
        // resetting all options
        if (this.get('activeOption') !== numOptions - 1) {
          $(allOptions).removeClass('Bourbon--active');
        }

        if (this.get('activeOption') === null) {
          this.set('activeOption', 0);
        } else if (this.get('activeOption') >= 0 && this.get('activeOption') < numOptions - 1) {
          this.set('activeOption', this.get('activeOption') + 1);
        }

        this.selectOption(allOptions, list);
        // e.keyCode 38 is for 'up arrow'
      } else if (e.keyCode === 38) {
        if (this.get('activeOption') === null) {
          return;
        }

        if (this.get('activeOption') > 0 && this.get('activeOption') < numOptions) {
          if (this.get('activeOption') !== numOptions) {
            $(allOptions).removeClass('Bourbon--active');
          }

          this.set('activeOption', this.get('activeOption') - 1);
          this.selectOption(allOptions, list);
        }
      }
    },
    getSelection: function getSelection() {
      var path = this.get('_valuePath');
      if (path && this.get('value') && this.get('content')) {
        return this.get('content').findBy(path, this.get('value'));
      } else {
        return this.get('value');
      }
    },
    selectOption: function selectOption(allOptions, list) {
      var selectedOption = allOptions[this.get('activeOption')];
      this.scrollList(selectedOption, list);
      $(selectedOption).addClass('Bourbon--active');
    },
    scrollList: function scrollList(item, list) {
      var itemHeight = this.get('activeOption') * item.scrollHeight;
      list.scrollTop(itemHeight);
    }
  });
});