define('bourbon/components/bourbon-modal-overlay', ['exports', 'bourbon/templates/components/bourbon-modal-overlay'], function (exports, _bourbonModalOverlay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['BourbonModal-overlay'],
    layout: _bourbonModalOverlay.default,
    click: function click() {
      this.get('closeModal')();
    }
  });
});