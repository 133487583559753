define('bourbon/components/new-bourbon-select-field-option', ['exports', 'bourbon/templates/components/new-bourbon-select-field-option'], function (exports, _newBourbonSelectFieldOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _newBourbonSelectFieldOption.default,
    tagName: 'li',
    classNames: ['NewBourbonSelectField-option', 'BourbonSelectField-option'],
    classNameBindings: ['selected:btw-bg-concrete', 'active:btw-bg-concrete'],
    attributeBindings: ['isDisabled:disabled', 'active', 'selected:selected', 'tabindex', 'role', 'selected:aria-selected'],
    label: null,
    active: false,
    selected: false,
    isDisabled: Ember.computed('disabled', function () {
      // returning false just sets it to the string "false"
      // returning undefined makes it just ignore it.
      return this.get('disabled') || undefined;
    })
  });
});