jQuery.fn.cssRem = function(property) {
  return this.cssFloat(property) / $('body').cssFloat('font-size');
};

jQuery.fn.cssInt = function(prop) {
  return parseInt(this.css(prop), 10) || 0;
};

jQuery.fn.cssFloat = function(prop) {
  return parseFloat(this.css(prop)) || 0;
};
