define('bourbon/tailwind/config/max-height', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    full: '100%',
    screen: '100vh'
  };
});