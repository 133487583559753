define('bourbon/components/bourbon-tooltip', ['exports', 'bourbon/templates/components/bourbon-tooltip'], function (exports, _bourbonTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bourbonTooltip.default,
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        tooltipClass: 'BourbonTooltip--dark',
        tooltipPosition: this.get('position') || 'top',
        tooltipSpacing: this.get('spacing') || 10,
        tooltipEvent: this.get('event') || 'hover',
        tooltipBackgroundColor: this.get('background') || 'dark',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              escapeWithReference: false
            }
          }
        }
      });

      this.set('tooltipBackgroundClass', 'BourbonTooltip--' + this.get('tooltipBackgroundColor') + ' BourbonTooltip--' + this.get('tooltipPosition') + ' BourbonTooltip ember-tooltip');
    }
  });
});