define('bourbon/components/bourbon-select-field', ['exports', 'bourbon/mixins/select', 'bourbon/mixins/click-handler', 'bourbon/templates/components/bourbon-select-field'], function (exports, _select, _clickHandler, _bourbonSelectField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_select.default, _clickHandler.default, {
    layout: _bourbonSelectField.default,
    classNames: ['BourbonSelectField'],
    classNameBindings: ['fullWidth:btw-block', 'showList:BourbonSelectField--active', 'disabled:BourbonSelectField--disabled'],
    content: null,
    optionValuePath: null,
    optionLabelPath: null,
    optionEnabledPath: null,
    groupedContent: false,
    showList: false,
    prompt: null,
    hasPrompt: Ember.computed.notEmpty('prompt'),
    value: null,
    activeOption: null,
    disabled: false,

    clickHandler: function clickHandler(e) {
      if (e.target !== document.activeElement || document.activeElement.textContent != this.get('label')) {
        this.set('activeOption', null);
        this.set('showList', false);
      }
    },
    keyDown: function keyDown(e) {
      this.moveUpDown(e);
      // e.keyCode 13 is for 'Enter'
      if (e.keyCode === 13) {
        e.preventDefault();
        this.send('updateSelection', this.get('activeOption'));
        this.set('showList', false);
        this.set('activeOption', null);
        document.activeElement.blur();
      }
    },


    selection: Ember.computed('value', 'content.[]', {
      get: function get(key) {
        this.getSelection();
      },
      set: function set(key, value) {
        if (Ember.isPresent(value)) {
          if (this.get('groupedContent') && value.groupHeader) {
            value = value.items[0];
          }
          this.setValue(value);
        } else {
          this.set('value', null);
        }
        this.set('activeOption', null);
        return value;
      }
    }),

    action: null,

    _sendAction: Ember.observer('selection', function () {
      if (typeof this.get('action') === 'function') {
        this.get('action')(this.get('selection'));
      }
    }),

    _initSelection: Ember.observer('content', 'value', function () {
      this.send('updateSelection');
    }),

    didInsertElement: function didInsertElement() {
      this.send('updateSelection');
    },


    actions: {
      updateSelection: function updateSelection(selectedIndex) {
        if (selectedIndex === undefined) {
          if (Ember.isPresent(this.findValueObject(this.get('value')))) {
            this.set('selection', this.findValueObject(this.get('value')));
            return;
          } else {
            selectedIndex = 0;
            if (this.get('prompt')) {
              selectedIndex -= 1;
            }
          }
        }

        this.set('selection', this.get('content').objectAt(selectedIndex));
      },
      mouseDown: function mouseDown() {
        this.set('showList', !this.get('showList'));
      }
    }
  });
});