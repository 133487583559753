define('bourbon/components/bourbon-toggle', ['exports', 'bourbon/templates/components/bourbon-toggle'], function (exports, _bourbonToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bourbonToggle.default,
    tagName: 'label',
    classNames: ['BourbonToggle', 'BourbonToggle--root'],
    classNameBindings: ['disabled:BourbonToggle--disabled', 'value:BourbonToggle--on', 'small:BourbonToggle--sizeSmall'],
    attributeBindings: ['label:aria-label'],

    ariaRole: 'button',
    value: null,
    disabled: false,
    action: null,
    small: false,

    label: Ember.computed('value', 'disabled', function () {
      return 'Toggle button ' + (this.get('value') ? 'on' : 'off') + (this.get('disabled') ? ' and disabled' : '');
    }),

    toggleState: Ember.computed('value', function () {
      return '' + (this.get('value') ? 'on' : 'off');
    }),

    click: function click() {
      if (this.get('disabled')) {
        return false;
      }

      this.set('value', !this.get('value'));

      if (this.get('action')) {
        this.sendAction('action');
      }
    }
  });
});