define('bourbon/components/bourbon-accordion', ['exports', 'bourbon/templates/components/bourbon-accordion'], function (exports, _bourbonAccordion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bourbonAccordion.default,
    tagName: 'ul',
    classNames: ['BourbonAccordion']
  });
});