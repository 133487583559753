define('bourbon/tailwind/config/min-height', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '0': '0',
    full: '100%',
    button: '32px',
    'button-icon': '45px',
    screen: '100vh'
  };
});