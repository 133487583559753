define('bourbon/components/bourbon-modal', ['exports', 'bourbon/templates/components/bourbon-modal'], function (exports, _bourbonModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),
    classNames: ['BourbonModal-container'],
    classNameBindings: ['modalService.showModalState:btw-fixed'],

    buttonOneClass: Ember.computed('modalService.buttonOneType', function () {
      return 'BourbonButton--' + this.get('modalService.buttonOneType');
    }),

    buttonTwoClass: Ember.computed('modalService.buttonTwoType', function () {
      return 'BourbonButton--' + this.get('modalService.buttonTwoType') + ' btw-mr-3';
    }),

    showCloseButton: Ember.computed('modalService.dismissable', 'modalService.notificationModal', function () {
      if (this.get('modalService.notificationModal')) {
        return !this.get('modalService.notificationModal');
      } else if (this.get('modalService.dismissable')) {
        return this.get('modalService.dismissable');
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      //  allow the user to escape the modal using the ESC key
      document.addEventListener('keydown', function (e) {
        if (this.get('modalService.showModalState') === true && e.keyCode === 27 && this.get('modalService.dismissable') === true) {
          this._closeModalActions();
        }
      }.bind(this), true);
    },


    layout: _bourbonModal.default,

    _closeModalActions: function _closeModalActions() {
      if (typeof this.get('modalService.closeAction') === 'function') {
        this.get('modalService.closeAction')();
      }

      if (this.get('modalService.dismissable') === true) {
        this.get('modalService').closeBourbonModal();
      }
    },


    actions: {
      closeBourbonModal: function closeBourbonModal() {
        this._closeModalActions();
      },
      buttonOneAction: function buttonOneAction() {
        // buttonAction might change the options so need to set a new variable
        var originalButtonOneDontClose = this.get('modalService.buttonOneDontClose');

        if (this.get('modalService.buttonOneAction')) {
          this.get('modalService.buttonOneAction')();
        }

        if (!originalButtonOneDontClose) {
          this.get('modalService').closeBourbonModal();
        }
      },
      buttonTwoAction: function buttonTwoAction() {
        // buttonAction might change the options so need to set a new variable
        var originalButtonTwoDontClose = this.get('modalService.buttonTwoDontClose');

        if (this.get('modalService.buttonTwoAction')) {
          this.get('modalService.buttonTwoAction')();
        }

        if (!originalButtonTwoDontClose) {
          this.get('modalService').closeBourbonModal();
        }
      },
      copyButtonAction: function copyButtonAction() {
        if (this.get('modalService.copyButtonAction')) {
          this.get('modalService.copyButtonAction')();
        }
        this.get('modalService').closeBourbonModal();
      }
    }
  });
});