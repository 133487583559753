define('bourbon/components/bourbon-select-field-option', ['exports', 'bourbon/templates/components/bourbon-select-field-option'], function (exports, _bourbonSelectFieldOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bourbonSelectFieldOption.default,
    classNames: ['BourbonSelectField-option'],
    classNameBindings: ['selected:btw-bg-concrete'],
    attributeBindings: ['selected', 'value', 'isDisabled:disabled', 'data-value', 'index'],
    tabindex: null,
    content: null,
    labelPath: null,
    valuePath: null,
    enabledPath: null,

    mouseDown: function mouseDown(e) {
      this.get('updateSelection')();
    },


    isDisabled: Ember.computed('content', 'enabledPath', function () {
      var path = this.get('enabledPath');
      // returning false just sets it to the string "false"
      // returning undefined makes it just ignore it.
      return path && !this.get(path) ? !this.get(path) : undefined;
    }),

    label: Ember.computed('content', 'labelPath', function () {
      var path = this.get('labelPath');
      return path ? this.get(path) : this.get('content');
    }),

    value: Ember.computed('content', 'valuePath', function () {
      var path = this.get('valuePath');
      return path ? this.get(path) : this.get('content');
    }),

    selected: Ember.computed('content', 'selection', function () {
      return this.get('content') === this.get('selection');
    })
  });
});