define("ember-composable-helpers/-private/create-needle-haystack-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createNeedleHaystackHelper;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var K = function K() {};

  /**
   * Creates a generic Helper class implementation that expects a `needle` and
   * `haystack` as arguments. A `fn` function is required to be passed in
   * that is invoked with the `needle` and `haystack` arguments.
   *
   * @private
   * @param  {Function} fn A function to run against the needle and haystack
   * @return {Any}
   */
  function createNeedleHaystackHelper() {
    var fn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : K;
    return Ember.Helper.extend({
      content: Ember.computed('needle.[]', 'haystack.[]', 'option', function () {
        var needle = Ember.get(this, 'needle');
        var haystack = Ember.get(this, 'haystack');
        var option = Ember.get(this, 'option');
        return fn(needle, haystack, option);
      }).readOnly(),
      compute: function compute(_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
          needle = _ref2[0],
          option = _ref2[1],
          haystack = _ref2[2];
        if (Ember.isEmpty(haystack)) {
          haystack = option;
          option = null;
        }
        Ember.set(this, 'needle', needle);
        Ember.set(this, 'haystack', haystack);
        Ember.set(this, 'option', option);
        return Ember.get(this, 'content');
      },
      contentDidChange: Ember.observer('content', function () {
        this.recompute();
      })
    });
  }
});