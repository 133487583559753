define('bourbon/components/new-bourbon-select-field', ['exports', 'bourbon/templates/components/new-bourbon-select-field', 'bourbon/mixins/click-handler'], function (exports, _newBourbonSelectField, _clickHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_clickHandler.default, {
    layout: _newBourbonSelectField.default,
    classNames: ['BourbonSelectField', 'NewBourbonSelectField'],
    classNameBindings: ['fullWidth:btw-block', 'showList:BourbonSelectField--active', 'disabled:BourbonSelectField--disabled'],
    attributeBindings: ['disabled:disabled', 'tabindex:tabindex'],

    // passed in
    content: null,
    prompt: null,
    value: null,
    optionLabelPath: null,
    optionValuePath: null,
    optionEnabledPath: null,
    action: null,
    // internal
    showList: false,
    activeDescendant: null,
    tabindex: '0',

    didRender: function didRender() {
      this.set('activeDescendant', $('.NewBourbonSelectField-option[aria-selected]').attr('id'));

      if (this.get('value') !== this.get('selection.value')) {
        this.set('value', this.get('selection.value'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('defaultSelection') && this.get('value') === undefined) {
        // need to check if defaultSelection is valid & only update if enabled
        var defaultSelectionIndex = this.get('content').indexOf(this.get('defaultSelection'));

        if (defaultSelectionIndex !== -1) {
          var defaultSelectionEnabled = this.get('internalContent')[defaultSelectionIndex].enabled;
          if (defaultSelectionEnabled) {
            this.set('selectedIndex', defaultSelectionIndex);
          }
        }
      }
    },
    clickHandler: function clickHandler(e) {
      if (!this.element.contains(e.target)) {
        this.set('showList', false);
      }
    },


    ariaExpanded: Ember.computed('showList', function () {
      return this.get('showList');
    }),

    _labelPath: Ember.computed('optionLabelPath', function () {
      if (Ember.isPresent(this.get('optionLabelPath'))) {
        return this.get('optionLabelPath').replace(/^content\.?/, '');
      }
    }),

    _valuePath: Ember.computed('optionValuePath', function () {
      if (Ember.isPresent(this.get('optionValuePath'))) {
        return this.get('optionValuePath').replace(/^content\.?/, '');
      }
    }),

    _enabledPath: Ember.computed('optionEnabledPath', function () {
      if (Ember.isPresent(this.get('optionEnabledPath'))) {
        return this.get('optionEnabledPath').replace(/^content\.?/, '');
      }
    }),

    internalContent: Ember.computed('content', '_labelPath', '_valuePath', '_enabledPath', function () {
      var _this = this;

      return Ember.A(this.get('content').map(function (item) {
        if (typeof item === 'string' || typeof item === 'number') {
          return Ember.Object.create({
            label: item.toString(),
            value: item,
            enabled: true
          });
        } else {
          var emberItem = typeof item.get == 'function' ? item : Ember.Object.create(item);

          var endItem = {};
          endItem.label = _this.get('_labelPath') ? emberItem.get(_this.get('_labelPath')) : emberItem.get('label');
          endItem.value = _this.get('_valuePath') ? emberItem.get(_this.get('_valuePath')) : emberItem;
          endItem.enabled = _this.get('_enabledPath') ? emberItem.get(_this.get('_enabledPath')) : true;

          return Ember.Object.create(endItem);
        }
      }));
    }),

    selectedIndex: Ember.computed('internalContent', 'value', {
      get: function get(key) {
        var valueHolder = this.get('internalContent').findBy('value', this.get('value'));

        var index = this.get('internalContent').indexOf(valueHolder);

        if (index !== -1 && !valueHolder.enabled || index === -1 && !this.get('prompt')) {
          // if value passed in that is not enabled need to check for the first enabled option
          index = this.get('internalContent').findIndex(function (element) {
            return !!element.enabled;
          });
        }

        if (index !== -1) {
          return index;
        } else {
          // if index = -1 and this.get('prompt') then show prompt
          return null;
        }
      },
      set: function set(key, value) {
        if (value !== -1) {
          this.set('value', this.get('internalContent').objectAt(value).get('value'));
        }
        return value;
      }
    }),

    activeIndex: Ember.computed('selectedIndex', {
      get: function get(key) {
        return this.get('selectedIndex');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    selection: Ember.computed('selectedIndex', function () {
      var index = this.get('selectedIndex');

      // don't set selection if index is null or undefined
      if (Ember.isPresent(index) && index !== -1) {
        return this.get('internalContent').objectAt(this.get('selectedIndex'));
      } else {
        return null;
      }
    }),

    label: Ember.computed('selection', 'prompt', function () {
      var selection = this.get('selection');
      if (Ember.isPresent(selection)) {
        return selection.label;
      } else {
        return this.get('prompt');
      }
    }),

    keyDown: function keyDown(e) {
      var el = $(e.currentTarget);
      var list = el.find('.BourbonSelectField-menu');

      if (e.keyCode === 38) {
        // up arrow
        this.moveActiveUp(this.get('activeIndex'));
        var itemHeight = el.find('.NewBourbonSelectField-option')[this.get('activeIndex') + 1].offsetHeight;
        var scrollHeight = (this.get('activeIndex') - 1) * itemHeight;
        list.scrollTop(scrollHeight);
      } else if (e.keyCode === 40) {
        // down arrow
        this.moveActiveDown(this.get('activeIndex'));
        var _itemHeight = el.find('.NewBourbonSelectField-option')[this.get('activeIndex') - 1].offsetHeight;
        var _scrollHeight = this.get('activeIndex') * _itemHeight;
        list.scrollTop(_scrollHeight);
      } else if (e.keyCode === 13) {
        //  enter
        if (this.get('showList')) {
          this.set('selectedIndex', this.get('activeIndex'));
          // triggers the focusOut to hide the list
          document.activeElement.blur();
          this.set('showList', false);
        } else {
          // When user is focused in and presses
          // enter we want to show the list
          this.set('showList', true);
        }
      }
    },
    moveActiveUp: function moveActiveUp(prevIndex) {
      var nextIndex = prevIndex - 1;
      if (nextIndex < 0) {
        return;
      } else {
        if (!this.get('internalContent').objectAt(nextIndex).get('enabled')) {
          this.moveActiveUp(nextIndex);
        } else {
          this.set('activeIndex', nextIndex);
        }
      }
    },
    moveActiveDown: function moveActiveDown(prevIndex) {
      var nextIndex = prevIndex === null ? 0 : prevIndex + 1;
      if (nextIndex >= this.get('internalContent.length')) {
        return;
      } else {
        if (!this.get('internalContent').objectAt(nextIndex).get('enabled')) {
          this.moveActiveDown(nextIndex);
        } else {
          this.set('activeIndex', nextIndex);
        }
      }
    },


    actions: {
      selectIndex: function selectIndex(index) {
        this.set('selectedIndex', index);
        // to deal with focusOut not being triggered upon selection in Safari & FF
        this.set('showList', false);
      },
      mouseDown: function mouseDown() {
        // needed to explicitly add focus here because Safari was adding focus to
        // a different element than all the other browsers
        this.$('.BourbonSelectField-selected').focus();
        this.set('showList', !this.get('showList'));
      }
    }
  });
});