define('bourbon/components/bourbon-button', ['exports', 'bourbon/templates/components/bourbon-button'], function (exports, _bourbonButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bourbonButton.default,
    tagName: 'button',
    classNames: ['BourbonButton'],
    classNameBindings: ['iconTextButton:BourbonButton-iconText', 'iconOnlyButton:BourbonButton-icon', 'fullWidth:BourbonButton--fullWidth'],
    attributeBindings: ['aria-label', 'data-role', 'data-hint', 'target', 'tabindex', 'title'],
    iconTextButton: Ember.computed('icon', 'title', function () {
      return this.get('icon') && this.get('title');
    }),
    iconOnlyButton: Ember.computed('icon', 'title', 'class', function () {
      return this.get('icon') && !this.get('class');
    }),
    textAndIconButton: Ember.computed('icon', 'title', 'class', function () {
      return this.get('icon') && this.get('class');
    }),

    click: function click() {
      if (typeof this.get('action') === 'function') {
        this.get('action')();
      } else {
        /* eslint no-console: 0 */
        console.warn('warning: no button action passed');
      }
    }
  });
});