define('bourbon/mixins/modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modalService: Ember.inject.service('modal'),

    actions: {
      showBourbonModal: function showBourbonModal(options) {
        this.get('modalService').showBourbonModal(options);
      }
    }
  });
});